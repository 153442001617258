import _crossvent from "crossvent";
import _throttle from "./throttle";
import _tailormade from "./tailormade";
var exports = {};
var crossvent = _crossvent;
var throttle = _throttle;
var tailormade = _tailormade;

function bullseye(el, target, options) {
  var o = options;
  var domTarget = target && target.tagName;

  if (!domTarget && arguments.length === 2) {
    o = target;
  }

  if (!domTarget) {
    target = el;
  }

  if (!o) {
    o = {};
  }

  var destroyed = false;
  var throttledWrite = throttle(write, 30);
  var tailorOptions = {
    update: o.autoupdateToCaret !== false && update
  };
  var tailor = o.caret && tailormade(target, tailorOptions);
  write();

  if (o.tracking !== false) {
    crossvent.add(window, "resize", throttledWrite);
  }

  return {
    read: readNull,
    refresh: write,
    destroy: destroy,
    sleep: sleep
  };

  function sleep() {
    tailorOptions.sleeping = true;
  }

  function readNull() {
    return read();
  }

  function read(readings) {
    var bounds = target.getBoundingClientRect();
    var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

    if (tailor) {
      readings = tailor.read();
      return {
        x: (readings.absolute ? 0 : bounds.left) + readings.x,
        y: (readings.absolute ? 0 : bounds.top) + scrollTop + readings.y + 20
      };
    }

    return {
      x: bounds.left,
      y: bounds.top + scrollTop
    };
  }

  function update(readings) {
    write(readings);
  }

  function write(readings) {
    if (destroyed) {
      throw new Error("Bullseye can't refresh after being destroyed. Create another instance instead.");
    }

    if (tailor && !readings) {
      tailorOptions.sleeping = false;
      tailor.refresh();
      return;
    }

    var p = read(readings);

    if (!tailor && target !== el) {
      p.y += target.offsetHeight;
    }

    el.style.left = p.x + "px";
    el.style.top = p.y + "px";
  }

  function destroy() {
    if (tailor) {
      tailor.destroy();
    }

    crossvent.remove(window, "resize", throttledWrite);
    destroyed = true;
  }
}

exports = bullseye;
export default exports;