var exports = {};

function throttle(fn, boundary) {
  var last = -Infinity;
  var timer;
  return function bounced() {
    if (timer) {
      return;
    }

    unbound();

    function unbound() {
      clearTimeout(timer);
      timer = null;
      var next = last + boundary;
      var now = Date.now();

      if (now > next) {
        last = now;
        fn();
      } else {
        timer = setTimeout(unbound, next - now);
      }
    }
  };
}

exports = throttle;
export default exports;