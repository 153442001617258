import _sell from "sell";
import _crossvent from "crossvent";
import _seleccion from "seleccion";
import _throttle from "./throttle";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var sell = _sell;
var crossvent = _crossvent;
var seleccion = _seleccion;
var throttle = _throttle;
var getSelection = seleccion.get;
var props = ["direction", "boxSizing", "width", "height", "overflowX", "overflowY", "borderTopWidth", "borderRightWidth", "borderBottomWidth", "borderLeftWidth", "paddingTop", "paddingRight", "paddingBottom", "paddingLeft", "fontStyle", "fontVariant", "fontWeight", "fontStretch", "fontSize", "fontSizeAdjust", "lineHeight", "fontFamily", "textAlign", "textTransform", "textIndent", "textDecoration", "letterSpacing", "wordSpacing"];
var win = _global;
var doc = document;
var ff = win.mozInnerScreenX !== null && win.mozInnerScreenX !== void 0;

function tailormade(el, options) {
  var textInput = el.tagName === "INPUT" || el.tagName === "TEXTAREA";
  var throttledRefresh = throttle(refresh, 30);
  var o = options || {};
  bind();
  return {
    read: readPosition,
    refresh: throttledRefresh,
    destroy: destroy
  };

  function noop() {}

  function readPosition() {
    return (textInput ? coordsText : coordsHTML)();
  }

  function refresh() {
    if (o.sleeping) {
      return;
    }

    return (o.update || noop)(readPosition());
  }

  function coordsText() {
    var p = sell(el);
    var context = prepare();
    var readings = readTextCoords(context, p.start);
    doc.body.removeChild(context.mirror);
    return readings;
  }

  function coordsHTML() {
    var sel = getSelection();

    if (sel.rangeCount) {
      var range = sel.getRangeAt(0);
      var needsToWorkAroundNewlineBug = range.startContainer.nodeName === "P" && range.startOffset === 0;

      if (needsToWorkAroundNewlineBug) {
        return {
          x: range.startContainer.offsetLeft,
          y: range.startContainer.offsetTop,
          absolute: true
        };
      }

      if (range.getClientRects) {
        var rects = range.getClientRects();

        if (rects.length > 0) {
          return {
            x: rects[0].left,
            y: rects[0].top,
            absolute: true
          };
        }
      }
    }

    return {
      x: 0,
      y: 0
    };
  }

  function readTextCoords(context, p) {
    var rest = doc.createElement("span");
    var mirror = context.mirror;
    var computed = context.computed;
    write(mirror, read(el).substring(0, p));

    if (el.tagName === "INPUT") {
      mirror.textContent = mirror.textContent.replace(/\s/g, "\xA0");
    }

    write(rest, read(el).substring(p) || ".");
    mirror.appendChild(rest);
    return {
      x: rest.offsetLeft + parseInt(computed["borderLeftWidth"]),
      y: rest.offsetTop + parseInt(computed["borderTopWidth"])
    };
  }

  function read(el) {
    return textInput ? el.value : el.innerHTML;
  }

  function prepare() {
    var computed = win.getComputedStyle ? getComputedStyle(el) : el.currentStyle;
    var mirror = doc.createElement("div");
    var style = mirror.style;
    doc.body.appendChild(mirror);

    if (el.tagName !== "INPUT") {
      style.wordWrap = "break-word";
    }

    style.whiteSpace = "pre-wrap";
    style.position = "absolute";
    style.visibility = "hidden";
    props.forEach(copy);

    if (ff) {
      style.width = parseInt(computed.width) - 2 + "px";

      if (el.scrollHeight > parseInt(computed.height)) {
        style.overflowY = "scroll";
      }
    } else {
      style.overflow = "hidden";
    }

    return {
      mirror: mirror,
      computed: computed
    };

    function copy(prop) {
      style[prop] = computed[prop];
    }
  }

  function write(el, value) {
    if (textInput) {
      el.textContent = value;
    } else {
      el.innerHTML = value;
    }
  }

  function bind(remove) {
    var op = remove ? "remove" : "add";
    crossvent[op](el, "keydown", throttledRefresh);
    crossvent[op](el, "keyup", throttledRefresh);
    crossvent[op](el, "input", throttledRefresh);
    crossvent[op](el, "paste", throttledRefresh);
    crossvent[op](el, "change", throttledRefresh);
  }

  function destroy() {
    bind(true);
  }
}

exports = tailormade;
export default exports;